import GettingStarted from './components/GettingStarted/GettingStarted';
import PageNotFound from './components/PageNotFound';
import ApplicantProfileContainer from './containers/applicant_profile';
import ApplicationContainer from './containers/application';
import ApplicationDocumentationContainer from './containers/application_documentation';
import CandidateDashboardContainer from './containers/candidatedashboard';
import CandidatesListContainer from './containers/candidateslist';
import CurrentUserPermissionsProvider from './containers/CurrentUserPermissionsProvider';
import DashboardContainer from './containers/dashboard';
import PreferencesContainer from './containers/preferences';
import PipelineMetricsDashboard from './features/PipelineMetricsDashboard';
import EnterProspectForm from './features/Prospects/EnterProspectForm';
import Layout from './Layout';

import PublicRoutes from 'components/PublicRoutes';
import { NetworkRequestStatusProvider } from 'context/networkRequestStatusContext';
import FlatFileTransfer from 'features/FlatFileTransferDashboard';
import { Redirect, Route, Switch } from 'react-router-dom';
import ApplicationConfirmationComponent from './components/ApplicationConfirmation';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UserAuthorized from './components/UserAuthorized';
import ATSRoute from './containers/ATSRoute';
import Dashboards from './containers/Dashboards';
import District from './containers/District';
import DistrictUserJobCreateContainer from './containers/DistrictUserJobCreate';
import EditReferenceContainer from './containers/editreference';
import ProfileContainer from './containers/profile';
import School from './containers/School';
import ScorecardEditContainer from './containers/scorecardedit';
import TalentMarketplace from './containers/TalentMarketplace';
import { FeatureContextProvider } from './context/featureContext';
import { FlatFileTransferFields } from './features/FlatFileTransferDashboard/FlatFileTransferFields';
import { CreateAndUpdateProfileForm } from './features/Connect/features/ExpressInterest/CreateAndUpdateProfileForm';
import { PreferencesForm } from './features/Connect/features/ExpressInterest/PreferencesForm';
import {
  adminUserTypes,
  checklistUserTypes,
  dashboardsUserTypes,
  districtAdminUserTypes,
  schoolOrDistrictAuthUserTypes,
  superAdminUserType,
} from './utils/userType';
import ScorecardPrintView from 'features/PrintView/ScorecardPrintView';
import { ThemeProvider } from '@mui/material';
import { nimbleTheme } from 'theme';
import { ReferencePrintView } from 'features/PrintView/ReferencePrintView';
import { ResumeAndCertificationsForm } from 'features/Connect/features/ExpressInterest/ResumeAndCertificationsForm';
import { StateParamProvider } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { AdminLandingForm } from 'features/Connect/features/ConnectATSIntegration/AdminLandingForm';
import { SignupSourcesProvider } from 'context/signupSourcesContext';
import TrevorDashboard from './features/TrevorDashboard/TrevorDashboard';

const App: React.FC = () => {
  return (
    <FeatureContextProvider>
      <ThemeProvider theme={nimbleTheme}>
        <SignupSourcesProvider>
          <CurrentUserPermissionsProvider>
            <StateParamProvider>
              <Layout>
                <Switch>
                  {/* / school routes */}
                  <AuthenticatedRoute path="/school">
                    <School />
                  </AuthenticatedRoute>

                  {/* /district routes */}
                  <AuthenticatedRoute path="/district">
                    <District />
                  </AuthenticatedRoute>

                  {/* /dashboards routes */}
                  <AuthenticatedRoute path="/dashboards">
                    <Dashboards />
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/applicant-profile/:id">
                    <UserAuthorized allow={['candidate']}>
                      <ApplicantProfileContainer />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/application/:id">
                    <UserAuthorized allow={['candidate']}>
                      <ApplicationContainer />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/application-documentation/:id">
                    <UserAuthorized allow={['candidate']}>
                      <ApplicationDocumentationContainer />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/candidate-dashboard">
                    <UserAuthorized allow={['candidate']}>
                      <CandidateDashboardContainer />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/applicationconfirmation">
                    <UserAuthorized allow={['candidate']}>
                      <ApplicationConfirmationComponent />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/preferences">
                    <UserAuthorized allow={['candidate']}>
                      <PreferencesContainer />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/teacherprofile">
                    <UserAuthorized allow={['candidate']}>
                      <ProfileContainer />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/getting-started">
                    <UserAuthorized allow={checklistUserTypes}>
                      <GettingStarted />
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  {/* ATS Routes + dashboard auth */}
                  <ATSRoute path="/data/vacancy">
                    <UserAuthorized allow={dashboardsUserTypes}>
                      <DashboardContainer />
                    </UserAuthorized>
                  </ATSRoute>
                  <ATSRoute path="/data/pipeline" exact>
                    <UserAuthorized allow={dashboardsUserTypes}>
                      <PipelineMetricsDashboard />
                    </UserAuthorized>
                  </ATSRoute>

                  {/* requiresATS + superAdmin auth */}
                  <ATSRoute path="/editreference/:id">
                    <UserAuthorized allow={superAdminUserType}>
                      <EditReferenceContainer />
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/createreference">
                    <UserAuthorized allow={superAdminUserType}>
                      <EditReferenceContainer />
                    </UserAuthorized>
                  </ATSRoute>

                  {/* requiresATS + district admin auth */}
                  <ATSRoute path="/prospectentry">
                    <UserAuthorized allow={districtAdminUserTypes}>
                      <EnterProspectForm />
                    </UserAuthorized>
                  </ATSRoute>

                  {/* requiresLogin + requiresATS + prospect auth */}
                  <ATSRoute path="/prospectlist">
                    <UserAuthorized allow={districtAdminUserTypes}>
                      <CandidatesListContainer />
                    </UserAuthorized>
                  </ATSRoute>

                  {/* requiresATS + school or district auth */}
                  <ATSRoute path="/editscorecard/:id">
                    <UserAuthorized allow={schoolOrDistrictAuthUserTypes}>
                      <ScorecardEditContainer />
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/createscorecard">
                    <UserAuthorized allow={schoolOrDistrictAuthUserTypes}>
                      <ScorecardEditContainer />
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/view-scorecard/:id">
                    <UserAuthorized allow={schoolOrDistrictAuthUserTypes}>
                      <ScorecardPrintView />
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/data/datatransfer/fields/:id">
                    <UserAuthorized allow={schoolOrDistrictAuthUserTypes}>
                      <FlatFileTransferFields />
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/data/datatransfer">
                    <UserAuthorized allow={schoolOrDistrictAuthUserTypes}>
                      <FlatFileTransfer />
                    </UserAuthorized>
                  </ATSRoute>

                  {/* super and district admin auth */}
                  <ATSRoute path="/talent-marketplace">
                    <UserAuthorized allow={districtAdminUserTypes}>
                      <TalentMarketplace />
                    </UserAuthorized>
                  </ATSRoute>

                  {/* NimbleConnect routes */}
                  <AuthenticatedRoute path="/connect/profile">
                    <UserAuthorized allow={['candidate']}>
                      <NetworkRequestStatusProvider>
                        <CreateAndUpdateProfileForm />
                      </NetworkRequestStatusProvider>
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/connect/qualifications">
                    <UserAuthorized allow={['candidate']}>
                      <NetworkRequestStatusProvider>
                        <ResumeAndCertificationsForm />
                      </NetworkRequestStatusProvider>
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <AuthenticatedRoute path="/connect/preferences">
                    <UserAuthorized allow={['candidate']}>
                      <NetworkRequestStatusProvider>
                        <PreferencesForm />
                      </NetworkRequestStatusProvider>
                    </UserAuthorized>
                  </AuthenticatedRoute>

                  <ATSRoute path="/connect/import-candidates">
                    <UserAuthorized allow={adminUserTypes}>
                      <NetworkRequestStatusProvider>
                        <AdminLandingForm />
                      </NetworkRequestStatusProvider>
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/dashboard/oecosl">
                    <UserAuthorized allow={superAdminUserType}>
                      <NetworkRequestStatusProvider>
                        <TrevorDashboard />
                      </NetworkRequestStatusProvider>
                    </UserAuthorized>
                  </ATSRoute>

                  <ATSRoute path="/profile/:id/reference/:id">
                    <ReferencePrintView />
                  </ATSRoute>

                  {/* Not working on prod */}
                  <AuthenticatedRoute
                    path="/districtuser/jobcreate"
                    component={DistrictUserJobCreateContainer}
                  />

                  {/* Dashboard Redirect */}
                  <Redirect from="/dashboard" to="/data/vacancy" />

                  {/* Candidate home is deprecated, so as a fallback we redirect them to preferences page */}
                  <Redirect from="/candidate/home" to="/preferences" />

                  {/* { public routes } */}
                  <Route path="*">
                    <PublicRoutes fallback={<PageNotFound />} />
                  </Route>
                </Switch>
              </Layout>
            </StateParamProvider>
          </CurrentUserPermissionsProvider>
        </SignupSourcesProvider>
      </ThemeProvider>
    </FeatureContextProvider>
  );
};

export default App;

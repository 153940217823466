import React from 'react';
import axios from 'axios';
import TrevorDashboardBody from './components/TrevorDashboardBody';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

const fetchDashboard = async (): Promise<unknown> => {
  return axios.get(`/api/trevor_dashboard/resolve_url/`).then((res) => res.data);
};

interface DashboardResponse {
  next_url: string;
}

const useQueryResolveUrl = (): UseQueryResult<DashboardResponse, AxiosError | null> => {
  return useQuery({
    queryKey: ['trevorDashboard'],
    queryFn: fetchDashboard,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
};

const TrevorDashboard = () => {
  const { data, error, isFetching } = useQueryResolveUrl();

  const nextUrl = data?.next_url;

  return <TrevorDashboardBody isFetching={isFetching} error={error} iframeUrl={nextUrl} />;
};

export default React.memo(TrevorDashboard);
